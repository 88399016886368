<template>
  <div>
    <button
      class="btn btn-primary"
      data-testid="toolbar-create"
      @click="createStandaloneAnnotation"
    >
      <PlusIcon class="feather-sm mr-1"></PlusIcon>Create
    </button>
  </div>
</template>

<script>
import PlusIcon from "../assets/plus.svg"
export default {
  components: {
    PlusIcon,
  },
  methods: {
    createStandaloneAnnotation() {
      const { gridApi } = this.$store.state
      const event = new Event("createStandaloneAnnotation")
      gridApi.dispatchEvent(event)
    },
  },
}
</script>

<style></style>
